import React from 'react';
import './App.css'; 


const Introduction = () => {
  return (
    <div>
      
      
      <h1 style={{paddingTop: 30}}>What Is It?</h1>
      <p style={{marginTop: '1.25rem'}}>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
        standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to
        make a type specimen book.
      </p>
      <p>
        It was popularised in the 1960s with the release of Letraset
        sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus
        PageMaker including versions of Lorem Ipsum.
      </p>
    </div>
  );
}

export default Introduction;
